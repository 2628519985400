@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    scroll-behavior: smooth;
  }
}

@layer components {
  .container {
    @apply mx-auto px-4 max-w-7xl;
  }
  
  .btn {
    @apply px-6 py-3 rounded-full font-medium transition-all duration-300 inline-flex items-center justify-center;
  }
  
  .btn-primary {
    @apply bg-brand-600 text-white hover:bg-brand-700;
  }
  
  .btn-secondary {
    @apply bg-white text-brand-600 hover:bg-gray-100;
  }
}